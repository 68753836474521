<div class="container" style="margin-top: 155px;">
    <!-- <div class="row">
        <div style="width: 100%; height: 0.1px; background-color: #252524;"></div>
    </div> -->
    <div class="row from-top">
        <div class="col-12 titel">كشاف الكتب</div>
    </div>
    <div class="row from-top">
        <div class="col-12 text-right text-titel">
            <img src="assets/image/home-icon.svg" width="18px" style="padding-bottom: 2px;" />
            الرئيسية > كشاف الكتب
        </div>
    </div>
    <div class="row from-top">
        <div class="col" style="text-align: right;">
            <input (keyup)="onKey($event)" class="input-search-titel" placeholder="إبحث عن الكـتب">
            <button (click)="findByBook(bookFind)" class="button-search">
                <img src="assets/image/search-icon.svg" alt="">
            </button>
        </div>
    </div>

    <div class="row from-top">
        <div class="col">
            <div class="text-titel">تصنيف الكتب</div>
        </div>
    </div>
    <div class="row text-titel">
        <div class="col-1">
            <button (click)="JosonToObject(0)" class="buttonFilter">
                <div class="sort-text"> الكل</div>
            </button>
        </div>
        <div class="col-1">
            <button (click)="JosonToObject(4)" class="buttonFilter">
                <div class="sort-text">الحديث</div>
            </button>
        </div>
        <div class="col-1">
            <button (click)="JosonToObject(2)" class="buttonFilter">
                <div class="sort-text">العقيدة</div>
            </button>
        </div>
        <div class="col-1">
            <button (click)="JosonToObject(3)" class="buttonFilter">
                <div class="sort-text">الفقة</div>
            </button>
        </div>
        <div class="col-1">
            <button (click)="JosonToObject(1)" class="buttonFilter">
                <div class="sort-text">العلوم الشرعية</div>
            </button>
        </div>
    </div>
</div>
<div class="container vertical-scrollable from-top-books" style="text-align: center;">
    <div class="row d-flex" style="justify-content:center">
        <div class="col-3 book-comp" *ngFor="let book of bookList; let i = index">
            <div class="book">
                <img [src]="book.image" width="150px" height="190px" />
            </div>
            <div class="book-title">{{book.titel}}</div>
            <button class="book-but" (click)="onClickDownloadPdf(i)">تحميل</button>
            <a [href]="pdfUrl" class="button" (click)="onClickOpenPdf(i)" target="_blank">عرض</a>
            <!-- <img *ngIf="showDeleteIcon" (click)="deleteBook()" style="text-align: left;cursor: pointer" width="30px"
                src="/assets/icons/trash.svg" /> -->
        </div>
    </div>
    <div class="msg" *ngIf="bookList.length === 0">
        لا يوجد كتب
    </div>
</div>