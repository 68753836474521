import { Component, OnInit } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { ResponseVideo } from 'src/app/interface/response';
import { DBService } from 'src/app/service/db.service';

@Component({
  selector: 'app-videos-page',
  templateUrl: './videos-page.component.html',
  styleUrls: ['./videos-page.component.css']
})
export class VideosPageComponent implements OnInit {

  videoListLong: ResponseVideo[] = [];
  videoListShort: ResponseVideo[] = [];

  videoListFind: ResponseVideo[] = [];

  videoFind: string = "";

  constructor(private sanitizer: DomSanitizer, private dbService: DBService) { }

  ngOnInit(): void {
    this.scrollToUpPage();
    this.JosonToObject();
  }

  scrollToUpPage() {
    window.scrollTo(0, 0);
  }

  JosonToObject() {
    this.dbService.getVideoType(1).subscribe((data => {
      this.videoListLong = data;
    }))
    this.dbService.getVideoType(2).subscribe((data => {
      this.videoListShort = data;
    }))
    return this.videoListLong;
  }

  findByVideo(videosName: string) {
    if (videosName === null || videosName === "") {
      this.videoListLong = [];
      this.videoListLong = this.JosonToObject();
    }
    else {
      this.videoListFind = [];
      this.videoListLong.filter((data) => {
        if (data.titel.toLocaleLowerCase().includes(videosName)) {
          this.videoListFind.push({
            id: data.id,
            titel: data.titel,
            videoLink: data.videoLink,
            createdDate: data.createdDate,
            typeVideo: data.typeVideo

          });
        }
      })
      this.videoListLong = [];
      this.videoListLong = this.videoListFind;
    }
  }

  enableVido(url: any) {
    return this.sanitizer.bypassSecurityTrustResourceUrl(url);
  }

  onKey(event: any) {
    this.videoFind = event.target.value;
    this.findByVideo(event.target.value);
  }

}
