import { Component, OnInit } from '@angular/core';
import { DBService } from 'src/app/service/db.service';
import { ResponseFatwa } from 'src/app/interface/response';

@Component({
  selector: 'app-fatwa-page',
  templateUrl: './fatwa-page.component.html',
  styleUrls: ['./fatwa-page.component.css']
})
export class FatwaPageComponent implements OnInit {

  constructor(private dbService: DBService) { }

  fatwaList: ResponseFatwa[] = [];
  fatwaListFind: ResponseFatwa[] = [];
  fatwaTitle: string = "";
  toggle1 = true;
  toggle2 = false;
  showFull = false;


  ngOnInit(): void {
    this.scrollToUpPage();
    this.JosonToObject();

  }

  scrollToUpPage() {
    window.scrollTo(0, 0);
  }

  JosonToObject() {
    this.dbService.getFatwa().subscribe((data => {
      this.fatwaList = data;
    }))
    return this.fatwaList;
  }

  findByFatwa(fatwaTitle: string) {
    if (fatwaTitle === null || fatwaTitle === "") {
      this.fatwaList = [];
      this.fatwaList = this.JosonToObject();
    }
    else {
      this.fatwaListFind = [];
      this.fatwaList.filter((data) => {
        if (data.titel.toLocaleLowerCase().includes(fatwaTitle)) {
          this.fatwaListFind.push({
            id: data.id,
            titel: data.titel,
            subject: data.subject,
            url: data.url,
            createdDate: data.createdDate
          });
        }
      })
      this.fatwaList = [];
      this.fatwaList = this.fatwaListFind;
    }
  }

  onKey(event: any) {
    this.fatwaTitle = event.target.value
    this.findByFatwa(event.target.value);
  }

  getShortContent(subject: string): string {
    const words = subject.split(' ');
    return words.length > 100 ? words.slice(0, 100).join(' ') : subject;
  }

  toggleContent(): void {
    this.showFull = !this.showFull;
  }
}
