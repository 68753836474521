import { Component, OnInit } from '@angular/core';
import * as AOS from "aos";
import { DBService } from 'src/app/service/db.service';


@Component({
  selector: 'app-home-page',
  templateUrl: './home-page.component.html',
  styleUrls: ['./home-page.component.css']
})
export class HomePageComponent implements OnInit {

  imageSrc: string = "";
  bookTitels: string[] = []
  articleTitels: string[] = []
  fatwaTitels: string[] = []
  videoTitels: string[] = []
  speecheTitels: string[] = []


  constructor(private dbService: DBService) { }

  ngOnInit(): void {
    AOS.init({ disable: 'mobile' });

    this.imageSrc = 'assets/image/homeBanner.jpg';

    const intervalId = setInterval(() => {
      const imageElement = document.querySelector('.image');
      // imageElement!.classList.add('image-changing');

      const images = ['assets/image/homeBanner2.jpg', 'assets/image/homeBanner.jpg'];
      let index = Math.floor(Math.random() * images.length);
      this.imageSrc = images[index];
      setTimeout(() => {
        // imageElement!.classList.remove('image-changing');
      }, 500);
    }, 5000);
    this.bookTitels = this.dbService.getTopThreeSubjectBookTitels();
    this.articleTitels = this.dbService.getTopThreeSubjectArticleTitels();
    this.fatwaTitels = this.dbService.getTopThreeSubjectFatwaTitels();
    this.videoTitels = this.dbService.getTopThreeSubjectVideoTitels();
    this.speecheTitels = [];
  }

  scrollToLastPage() {
    window.scrollTo({ left: 0, top: 2500, behavior: 'smooth' });
  }
}
