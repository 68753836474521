import { Component, OnInit, Renderer2, ElementRef } from '@angular/core';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { ResponseBook } from 'src/app/interface/response';
import { DBService } from 'src/app/service/db.service';
import { HttpClient, HttpHeaders, HttpResponse } from '@angular/common/http';
import { PopUpComponent } from 'src/app/pop-up/pop-up.component';


@Component({
  selector: 'app-books-page',
  templateUrl: './books-page.component.html',
  styleUrls: ['./books-page.component.css']
})

export class BooksPageComponent implements OnInit {

  bookList: ResponseBook[] = [];
  bookListFind: ResponseBook[] = [];
  bookFind: string = "";
  showDeleteIcon = false;
  pdfUrl = "";

  constructor(private sanitizer: DomSanitizer, private dbService: DBService, private http: HttpClient
    , private renderer: Renderer2, private elementRef: ElementRef) { }

  ngOnInit(): void {
    this.scrollToUpPage();
    this.JosonToObject(0)
    this.showDeleteIcon = this.dbService.getValueDeleteIcon()
  }

  scrollToUpPage() {
    window.scrollTo(0, 0);
  }

  JosonToObject(bookType: number) {
    if (bookType != 0) {
      this.dbService.getBookByType(bookType).subscribe((data => {
        this.bookList = data;
      }))
    }
    else {
      this.dbService.getBook().subscribe((data => {
        this.bookList = data;
      }))
    }
    return this.bookList;
  }



  onClickDownloadPdf(i: number) {
    let fileUrl = this.bookList[i].url;
    let fileName = this.bookList[i].titel;

    this.http.get<Blob>(fileUrl, { responseType: 'blob' as 'json' }).subscribe(
      (data: Blob) => {
        var file = new Blob([data], { type: 'application/pdf' })
        var fileURL = URL.createObjectURL(file);

        // if you want to open PDF in new tab
        // window.open(fileURL);
        var a = document.createElement('a');
        a.href = fileURL;
        a.target = '_blank';
        a.download = fileName + '.pdf';
        document.body.appendChild(a);
        a.click();
      },
      (error) => {
        console.log('getPDF error: ', error);
      }
    );
  }

  onClickOpenPdf(i: number) {
    this.pdfUrl = this.bookList[i].url;
  }


  findByBook(bookName: string) {
    if (bookName === null || bookName === "") {
      this.bookList = [];
      this.bookList = this.JosonToObject(0);
    }
    else {
      this.bookListFind = [];
      this.bookList.filter((data) => {
        if (data.titel.toLocaleLowerCase().includes(bookName)) {
          this.bookListFind.push({
            id: data.id,
            titel: data.titel,
            url: data.url,
            createdDate: data.createdDate,
            image: data.image,
            bookType: data.bookType,
          });
        }
      })
      this.bookList = [];
      this.bookList = this.bookListFind;
    }
  }

  onKey(event: any) {
    this.bookFind = event.target.value;
    this.findByBook(event.target.value);
  }

  deleteBook() {
    console.log("delete");
  }
}
