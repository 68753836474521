import { Component, OnInit } from '@angular/core';
import { ResponseSpeeches } from 'src/app/interface/response';
import { DBService } from 'src/app/service/db.service';

@Component({
  selector: 'app-speeches',
  templateUrl: './speeches.component.html',
  styleUrls: ['./speeches.component.css']
})
export class SpeechesComponent implements OnInit {
  speechesTitle: string = "";
  speechesList: ResponseSpeeches[] = [];
  speechesListFind: ResponseSpeeches[] = [];
  showFull = false;

  constructor(private dbService: DBService) { }

  ngOnInit(): void {
    this.scrollToUpPage();
    this.JosonToObject();
  }


  scrollToUpPage() {
    window.scrollTo(0, 0);
  }

  findBySpeeches(speechesTitle: string) {
    if (speechesTitle === null || speechesTitle === "") {
      this.speechesList = [];
      this.speechesList = this.JosonToObject();

    }
    else {
      this.speechesListFind = [];
      this.speechesList.filter((data) => {
        if (data.titel === speechesTitle) {
          this.speechesList = [];
          this.speechesListFind.push({
            id: data.id,
            titel: data.titel,
            subject: data.subject,
            url: data.url,
            image: data.image,
            createdDate: data.createdDate
          });
        }
      })
      this.speechesList = [];
      this.speechesList = this.speechesListFind;
    }
  }

  onKey(event: any) {
    this.speechesTitle = event.target.value
    this.findBySpeeches(event.target.value);
  }

  JosonToObject() {
    this.dbService.getSpeeches().subscribe((data => {
      this.speechesList = data;
    }))
    return this.speechesList;
  }

  getShortContent(subject: string): string {
    const words = subject.split(' ');
    return words.length > 100 ? words.slice(0, 100).join(' ') : subject;
  }

  toggleContent(): void {
    this.showFull = !this.showFull;
  }
}
